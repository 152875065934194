import {memo, useMemo, useState} from 'react'
import {graphql, useFragment, useLazyLoadQuery} from 'react-relay/hooks'
import {Box, Flex, Stack} from '@chakra-ui/react'
import {isAfter, isBefore} from 'date-fns'

import {GameFeed_games$key} from './__generated__/GameFeed_games.graphql'

import {buildTimeline} from './utils'

import {
  GameListItem,
  GameListItemCompact,
  GameListItemCompactSkeleton,
  GameListItemSkeleton,
  GameListItemWaitlist,
} from 'components/GameListItem'
import {MorePager} from 'components/MorePager'
import {GameFeedViewerQuery} from './__generated__/GameFeedViewerQuery.graphql'
import {GameListItemLocked} from 'components/GameListItem/GameListItem'

interface GameFeedProps {
  gameType: 'compact' | 'full'
  games: GameFeed_games$key
  onLoadMore?: () => void
}

const GAME_TYPE_COMPONENT_MAP = {
  compact: GameListItemCompact,
  full: GameListItem,
}

const GAME_SKELETON_TYPE_COMPONENT_MAP = {
  compact: GameListItemCompactSkeleton,
  full: GameListItemSkeleton,
}

export const GameFeed = memo(function GameFeed({
  games: _games,
  gameType,
  onLoadMore,
}: GameFeedProps) {
  const games = useFragment(
    graphql`
      fragment GameFeed_games on Game
      @argumentDefinitions(youth: {type: "Boolean!", defaultValue: false})
      @relay(plural: true) {
        ...GameFeedItem_games @arguments(youth: $youth)
        id
        startTime
      }
    `,
    _games
  )

  const timeline = useMemo(() => buildTimeline(games), [games])
  const labels = Object.keys(timeline)

  const GameListItemSkeletonToRender =
    GAME_SKELETON_TYPE_COMPONENT_MAP[gameType]

  return (
    <>
      {labels.map((label) => (
        <Box key={label} paddingBlockEnd="4" position="relative">
          {label !== labels[labels.length - 1] && (
            <Box
              bgColor="gray.300"
              borderRadius="full"
              bottom={0}
              display={{
                base: 'none',
                md: 'block',
              }}
              left="calc((1rem - 2px) / 2)"
              position="absolute"
              top={5}
              width="2px"
            />
          )}
          <Flex alignItems="center" bgColor="transparent" paddingInlineStart="6">
            <Box
              alignItems="center"
              bg="gray.300"
              borderRadius="full"
              display="flex"
              h={4}
              justifyContent="center"
              left={0}
              position="absolute"
              w={4}
              _before={{
                borderRadius: `full`,
                bgColor: `gray.100`,
                boxShadow: `sm`,
                content: `""`,
                display: `block`,
                height: '2',
                position: `absolute`,
                width: '2',
              }}
            />
            <Box
              color="gray.700"
              fontSize="xl"
              fontWeight="thin"
              lineHeight="none"
            >
              {label}
            </Box>
          </Flex>
          <Flex
            flexDirection="column"
            marginBlockStart="4"
            paddingInlineStart={{
              base: '0',
              md: '6',
            }}
            sx={{
              gap: 'var(--chakra-sizes-4)',
            }}
          >
            {timeline[label].map((game) => (
              <GameFeedItem key={game.id} game={game} gameType={gameType} />
            ))}
          </Flex>
        </Box>
      ))}
      {onLoadMore && (
        <MorePager onMore={onLoadMore}>
          <Stack ml={6} spacing={4}>
            <GameListItemSkeletonToRender />
            <GameListItemSkeletonToRender />
            <GameListItemSkeletonToRender />
          </Stack>
        </MorePager>
      )}
    </>
  )
})

interface GameFeedItemProps {
  game: any
  gameType: 'compact' | 'full'
}

function GameFeedItem(props: GameFeedItemProps) {
  const now = new Date()

  const GameListItemToRender = GAME_TYPE_COMPONENT_MAP[props.gameType]

  const {viewer} = useLazyLoadQuery<GameFeedViewerQuery>(
    graphql`
      query GameFeedViewerQuery {
        viewer {
          hasBeenGoalie
        }
      }
    `,
    {}
  )

  const game = useFragment(
    graphql`
      fragment GameFeedItem_games on Game
      @argumentDefinitions(youth: {type: "Boolean!", defaultValue: false}) {
        ...GameListItem_game @arguments(youth: $youth)
        ...GameListItemCompact_game @arguments(youth: $youth)
        ...GameListItemWaitlist_game
        ...GameListItemLocked_game
        id
        unlockTimeTz
        gameIsFull
        startTime
      }
    `,
    props.game
  )

  const unlockTime = new Date(game.unlockTimeTz)
  const [isLocked, setIsLocked] = useState(isAfter(unlockTime, now))

  function changeToNormalItem() {
    setIsLocked(false)
  }

  return isBefore(now, new Date(game.startTime)) &&
    game.gameIsFull &&
    props.gameType === 'full' ? (
    <GameListItemWaitlist
      game={game}
      key={game.id}
      hasBeenGoalie={viewer?.hasBeenGoalie ?? false}
    />
  ) : isLocked ? (
    <GameListItemLocked
      game={game}
      key={game.id}
      changeToNormalItem={changeToNormalItem}
    />
  ) : (
    <GameListItemToRender game={game} key={game.id} />
  )
}
